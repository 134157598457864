
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import router from "@/app/ui/router";
import { ExchangeRateController } from "@/app/ui/controllers/ExchangeRateController";
import {
  dateToDateString,
  formatDateNumber,
  formatDateWithoutTime,
  formatInputMoney
} from "@/app/infrastructures/misc/Utils";
import { LocationController } from "@/app/ui/controllers/LocationController";
import debounce from "lodash/debounce";
import SelectSearchV2 from "@/app/ui/components/select-search/index.vue";
import { CountryData } from "@/domain/entities/Location";

@Options({
  components: {
    Input,
    SelectSearchV2
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (this.hasChanged && !this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      ExchangeRateController.setOpenModalSuccess(false);
      this.name = "";
      next();
    }
  }
})
export default class Add extends Vue {
  goBack() {
    this.$router.back();
  }

  mounted() {
    ExchangeRateController.setErrorValidation(false);
    this.fetchCountryCurrency("");
  }

  // filter kurs awal
  kursAwalSelect = false;
  kursAwal: any = "";
  onSelectKursAwal(object: any) {
    this.kursAwal = object;
  }

  // kurs awal select
  resetSelectSearch = 0;
  fetchCountryCurrency = debounce((search: string) => {
    LocationController.getCountryList({
      search: search,
      status: "active",
      page: 1,
      limit: 10
    });
  }, 250);

  get isLoadingCountry() {
    return LocationController.isLoadingCountry;
  }

  get countryListData() {
    return LocationController.countryData.countryData
      .filter((key: CountryData) => {
        return key.name !== "INDONESIA";
      })
      .map(e => {
        return {
          name: e.name + " - " + e.currency,
          value: e.currency
        };
      });
  }

  // filter kurs tukar
  kursTukarData = [
    { name: "Pilih Kurs Tukar", value: "" },
    { name: "INDONESIA - IDR", value: "IDR" }
  ];
  kursTukarSelect = false;
  kursTukarName = "";
  kursTukarValue = "";
  onOpenKursTukarSelect() {
    this.kursTukarSelect = true;
  }
  onCloseKursTukarSelect() {
    this.kursTukarSelect = false;
  }
  onSelectKursTukar(name: string, value: string) {
    this.kursTukarName = name;
    this.kursTukarValue = value;
    this.onCloseKursTukarSelect();
  }

  // Modal Success
  messageSuccess = "";
  get openSuccess() {
    return ExchangeRateController.openModalSuccess;
  }
  onCloseSuccess() {
    router.push(`/tarif/exchange-rate`);
  }

  // Save data
  get openSave() {
    return ExchangeRateController.openModal;
  }
  onOpenSave() {
    ExchangeRateController.setOpenModal(true);
  }
  onCloseSave() {
    ExchangeRateController.setOpenModal(false);
  }
  _onCreate() {
    this.onCloseSave();
    ExchangeRateController._onAddExchangeRate({
      from: this.kursAwal?.value,
      to: this.kursTukarValue,
      exchangeRate: Number(this.exchangeRateValue.split(".").join("")),
      startDate: formatDateNumber(this.periodeStart),
      endDate: formatDateNumber(this.periodeEnd)
    });
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  get hasChanged() {
    return this.kursAwal !== "" || this.kursTukarName !== "";
  }

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  exchangeRateValue: any = 0;

  formatted() {
    if (/^0/.test(this.exchangeRateValue)) {
      this.exchangeRateValue = this.exchangeRateValue.replace(/^0/, "");
    } else {
      this.exchangeRateValue = this.exchangeRateValue.replace(/[\D]+/g, "");
      this.exchangeRateValue = formatInputMoney(this.exchangeRateValue);
    }
  }

  // disable
  get isDisabled() {
    return (
      !this.kursAwal ||
      !this.kursTukarValue ||
      !this.exchangeRateValue ||
      this.invalidStartDate ||
      this.invalidEndDate ||
      dateToDateString(this.periodeStart) === dateToDateString(this.periodeEnd)
    );
  }

  //Error
  get isError() {
    return ExchangeRateController.isError;
  }

  get isErrorValidation() {
    return ExchangeRateController.isErrorValidation;
  }

  get errorMessage() {
    return ExchangeRateController.errorMessage;
  }

  //Error Cause
  get errorCause() {
    return ExchangeRateController.errorCause;
  }

  handleErrorClose() {
    ExchangeRateController.handleError();
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;
  get periodeStart(): any {
    return ExchangeRateController.convertPeriodeStart;
  }
  get minDate() {
    const date = new Date();
    const minDate = new Date(date);
    minDate.setDate(minDate.getDate() + 1);
    return minDate;
  }
  get periodeEnd(): any {
    return ExchangeRateController.convertPeriodeEnd;
  }
  inputDateStart(param: Date) {
    ExchangeRateController.setPeriodeStart(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidStartDate = false;
      } else
        this.invalidStartDate = param.getHours() <= new Date().getHours() - 1;
    }
  }
  inputDateEnd(param: Date) {
    ExchangeRateController.setPeriodeEnd(param);
    if (
      formatDateWithoutTime(new Date()) ===
      formatDateWithoutTime(new Date(param))
    ) {
      if (
        param.getHours() === new Date().getHours() - 1 &&
        param.getMinutes() >= new Date().getMinutes()
      ) {
        this.invalidEndDate = false;
      } else
        this.invalidEndDate = param.getHours() <= new Date().getHours() - 1;
    }
  }

  // check online status
  online = window.navigator.onLine;
}
