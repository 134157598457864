
import { Options, Vue } from "vue-class-component";
import DetailLayout from "@/app/ui/layout/detail-layout.vue";
import {
  checkRolePermission,
  dateToDateString,
  formatDate,
  formatDateWithoutTime,
  formatPriceIDR
} from "@/app/infrastructures/misc/Utils";
import { PRICING_EXCHANGE_RATE } from "@/app/infrastructures/misc/RolePermission";
import { ExchangeRateController } from "@/app/ui/controllers/ExchangeRateController";

@Options({
  components: {
    DetailLayout
  },
  computed: {
    isEditAble() {
      return (
        checkRolePermission(PRICING_EXCHANGE_RATE.EDIT) &&
        this.detailData.status.toLowerCase() !== "expired"
      );
    }
  }
})
export default class Detail extends Vue {
  // misc variable
  get id(): number {
    return Number(this.$route.params.id);
  }

  get title(): string {
    return String(this.detailData.id);
  }

  goEdit() {
    this.$router.push(`/tarif/exchange-rate/${this.id}/edit`);
  }

  // Detail Data
  created() {
    this.getDetail();
  }

  getDetail() {
    ExchangeRateController.getDetailExchange({ id: this.id });
  }

  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";

  exchangeRateValue = "";
  startDate = "";
  endDate = "";

  // Detail Data
  get detailData() {
    const detail = ExchangeRateController.exchangeRateDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updatedAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updatedBy = detail.updatedBy;
    this.exchangeRateValue = formatPriceIDR(detail.exchangeRate);
    this.startDate = formatDateWithoutTime(detail.startDate);
    this.endDate = formatDateWithoutTime(detail.endDate);
    return detail;
  }

  // Loading
  get isLoading() {
    return ExchangeRateController.isLoading;
  }

  // route navigation
  goBack() {
    this.$router.push("/tarif/exchange-rate");
    ExchangeRateController.setPeriodeStart(new Date());
    ExchangeRateController.setPeriodeEnd(new Date());
  }

  // Format Periode
  formatPeriode(start: string, end: string) {
    return `${dateToDateString(start)} - ${dateToDateString(end)}`;
  }

  // Format Date
  formatDateToString(date: string) {
    return formatDate(date);
  }

  // Handle Error
  get isError() {
    return ExchangeRateController.isError;
  }
  get errorCause() {
    return ExchangeRateController.errorCause;
  }
}
