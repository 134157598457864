import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      isSelectStatus: true,
      isSelectKurs: true,
      isButtonAction: _ctx.isCreateAble,
      currentRoutes: _ctx.getCurrentRoute,
      statusData: _ctx.statusData,
      statusSelect: _ctx.statusSelect,
      onOpenStatusSelect: _ctx.onOpenStatusSelect,
      onCloseStatusSelect: _ctx.onCloseStatusSelect,
      onSelectStatus: _ctx.onSelectStatus,
      valueStatus: _ctx.statusName,
      kursAwalData: _ctx.kursAwalData,
      kursAwalSelect: _ctx.kursAwalSelect,
      onOpenKursAwalSelect: _ctx.onOpenKursAwalSelect,
      onCloseKursAwalSelect: _ctx.onCloseKursAwalSelect,
      onSelectKursAwal: _ctx.onSelectKursAwal,
      valueKursAwal: _ctx.kursAwalName,
      kursTukarData: _ctx.kursTukarData,
      kursTukarSelect: _ctx.kursTukarSelect,
      onOpenKursTukarSelect: _ctx.onOpenKursTukarSelect,
      onCloseKursTukarSelect: _ctx.onCloseKursTukarSelect,
      onSelectKursTukar: _ctx.onSelectKursTukar,
      valueKursTukar: _ctx.kursTukarName,
      buttonText: `Buat Konversi`,
      buttonAction: _ctx.toPageAdd,
      class: "z-20"
    }, null, 8, ["isButtonAction", "currentRoutes", "statusData", "statusSelect", "onOpenStatusSelect", "onCloseStatusSelect", "onSelectStatus", "valueStatus", "kursAwalData", "kursAwalSelect", "onOpenKursAwalSelect", "onCloseKursAwalSelect", "onSelectKursAwal", "valueKursAwal", "kursTukarData", "kursTukarSelect", "onOpenKursTukarSelect", "onCloseKursTukarSelect", "onSelectKursTukar", "valueKursTukar", "buttonAction"]),
    (_ctx.isError && !_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_ErrorMessage, {
          key: 0,
          errorType: _ctx.errorCause,
          onTryAgain: _ctx.onRequest
        }, null, 8, ["errorType", "onTryAgain"]))
      : (
        _ctx.exchangeData.length === 0 &&
          !_ctx.isLoading &&
          _ctx.pagination.page === 1 &&
          _ctx.firstRequest &&
          !_ctx.isError &&
          _ctx.kursAwalName === '' &&
          _ctx.kursTukarName === '' &&
          _ctx.statusName === ''
      )
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 1,
            data: "data Exchange Rate"
          }))
        : (_openBlock(), _createBlock(_component_TableV2, {
            key: 2,
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            loading: _ctx.isLoading,
            columns: _ctx.columns,
            data: _ctx.exchangeData,
            class: "my-2",
            onRequest: _ctx.onRequest,
            onClick: _ctx.onClickRow,
            isDetailAble: _ctx.isDetailAble
          }, null, 8, ["pagination", "loading", "columns", "data", "onRequest", "onClick", "isDetailAble"]))
  ]))
}