
import { Options, Vue } from "vue-class-component";
import { PRICING_EXCHANGE_RATE } from "@/app/infrastructures/misc/RolePermission";
import { ExchangeRateController } from "@/app/ui/controllers/ExchangeRateController";
import checkRolePermission from "@/app/infrastructures/misc/common-library/CheckRolePermission";
import formatDateWithoutTime from "@/app/infrastructures/misc/common-library/FormatDateWithoutTime";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import router from "@/app/ui/router";
import EmptyState from "@/app/ui/components/empty-list/index.vue";

@Options({
  components: {
    EmptyState
  },
  computed: {
    getCurrentRoute() {
      return this.$route.meta.title;
    },
    isCreateAble() {
      return checkRolePermission(PRICING_EXCHANGE_RATE.CREATE) && !this.isError;
    },
    isDetailAble() {
      return checkRolePermission(PRICING_EXCHANGE_RATE.DETAIL);
    }
  }
})
export default class ExchangeRate extends Vue {
  // filter status
  statusData = [
    { name: "Semua Status", value: "" },
    { name: "Pending", value: "pending" },
    { name: "Active", value: "active" },
    { name: "Expired", value: "expired" }
  ];
  statusSelect = false;
  statusName = "";
  onOpenStatusSelect() {
    this.statusSelect = true;
  }
  onCloseStatusSelect() {
    this.statusSelect = false;
  }
  onSelectStatus(name: string, value: string) {
    this.statusName = name;
    this.onCloseStatusSelect();
    ExchangeRateController.setStatusAction(value);
  }

  // filter kurs awal
  kursAwalData = [
    { name: "Semua Kurs Awal", value: "" },
    { name: "USD", value: "usd" },
    { name: "SGD", value: "sgd" },
    { name: "MYR", value: "MYR" }
  ];
  kursAwalSelect = false;
  kursAwalName = "";
  onOpenKursAwalSelect() {
    this.kursAwalSelect = true;
  }
  onCloseKursAwalSelect() {
    this.kursAwalSelect = false;
  }
  onSelectKursAwal(name: string, value: string) {
    this.kursAwalName = name;
    this.onCloseKursAwalSelect();
    ExchangeRateController.setFromAction(value);
  }

  // filter kurs tukar
  kursTukarData = [
    { name: "Semua Kurs Tukar", value: "" },
    { name: "IDR", value: "IDR" }
  ];
  kursTukarSelect = false;
  kursTukarName = "";
  onOpenKursTukarSelect() {
    this.kursTukarSelect = true;
  }
  onCloseKursTukarSelect() {
    this.kursTukarSelect = false;
  }
  onSelectKursTukar(name: string, value: string) {
    this.kursTukarName = name;
    this.onCloseKursTukarSelect();
    ExchangeRateController.setToAction(value);
  }

  // data list
  mounted() {
    ExchangeRateController.initExchangeRates();
  }

  onRequest() {
    ExchangeRateController.fetchExchangeRate();
  }

  get firstRequest() {
    return ExchangeRateController.firstRequest;
  }

  get exchangeData() {
    return ExchangeRateController.exchangeRateData.data;
  }

  // Table
  columns = [
    {
      name: "ID Rate",
      key: "id",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "text-red-lp-200 text-center",
      render: (item: any) => {
        return `<span class='text-left text-red-lp-200 flex'>${item.id}</span>`;
      }
    },
    {
      name: "Kurs Awal",
      styleHead: "w-2/12 whitespace-no-wrap text-left",
      styleBody: "",
      render: (item: any) => {
        return `<div class="flex text-black-lp-300">
                    <div class="rounded px-2 py-0 bg-gray-lp-400 font-medium">${item.exchangeRateFrom}</div>
                </div>`;
      }
    },
    {
      name: "Kurs Tukar",
      key: "kursTukar",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      styleBody: "rounded py-0",
      render: (item: any) => {
        return `<div class='text-black-lp-300 flex'>
                    <div class="rounded px-2 py-0 bg-gray-lp-400 font-medium">${item.exchangeRateTo}</div>
                </div>`;
      }
    },
    {
      name: "Nilai Tukar",
      key: "nilaiPenukaran",
      styleHead: "w-2/12 text-left whitespace-no-wrap",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300'>${formatPriceIDR(
          item.exchangeRate
        )}</div>`;
      }
    },
    {
      name: "Periode Konversi",
      key: "ratePeriode",
      styleHead: "w-2/12 text-left",
      styleBody: "text-left rounded py-0",
      render: (item: any) => {
        return `<div class='flex text-black-lp-300 whitespace-no-wrap'>${formatDateWithoutTime(
          item.startDate
        )} - ${formatDateWithoutTime(item.endDate)}</div>`;
      }
    },
    {
      name: "Status",
      key: "status",
      styleHead: "w-2/12 text-left",
      styleBody: "",
      render: (item: any) => {
        return `
         <div class="flex">
      <div
        class="rounded-full px-2 py-0 ${this.getClassBasedOnStatus(
          item.status.toLowerCase()
        )}"
      ><span class="capitalize font-medium">${item.status.toLowerCase()}</span></div></div>`;
      }
    }
  ];
  getClassBasedOnStatus(status: string) {
    if (status === "active") return "bg-green-lp-300 text-green-lp-200";
    if (status === "expired") return "bg-red-300 text-red-lp-200";
    return "bg-yellow-lp-200 text-yellow-lp-100";
  }

  // to detail page
  onClickRow(item: any) {
    this.$router.push(`/tarif/exchange-rate/${item.id}`);
  }

  // to Add Page
  toPageAdd() {
    router.push(`/tarif/exchange-rate/add`);
  }

  // Pagination Table
  get pagination() {
    return ExchangeRateController.exchangeRateData.pagination;
  }

  //Loading
  get isLoading() {
    return ExchangeRateController.isLoading;
  }

  //Error
  get isError() {
    return ExchangeRateController.isError;
  }

  //Error Cause
  get errorCause() {
    return ExchangeRateController.errorCause;
  }
}
