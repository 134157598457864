
import { Options, Vue } from "vue-class-component";
import Input from "@/app/ui/components/input/index.vue";
import Toggle from "@/app/ui/components/toggle/index.vue";
import dateToTime from "@/app/infrastructures/misc/common-library/DateToTime";
import ellipsisString from "@/app/infrastructures/misc/common-library/EllipsisString";
import formatDate from "@/app/infrastructures/misc/common-library/FormatDate";
import formatDateNumber from "@/app/infrastructures/misc/common-library/FormatDateNumber";
import formatInputMoney from "@/app/infrastructures/misc/common-library/FormatInputMoney";
import formatPriceWithoutCurrency from "@/app/infrastructures/misc/common-library/FormatPriceWithoutCurrency";
import dateToDateString from "@/app/infrastructures/misc/common-library/DateToDateString";
import { ExchangeRateController } from "@/app/ui/controllers/ExchangeRateController";

@Options({
  components: {
    Input,
    Toggle
  },
  beforeRouteLeave(to: any, from: any, next: any) {
    if (to.name === "login") {
      next();
    }
    if (!this.openSuccess) {
      this.onOpenClosePage(to);
      if (this.answer) {
        this.answer = false;
        next();
      } else {
        next(false);
      }
    } else {
      this.onCloseSuccess();
      next();
    }
  }
})
export default class Edit extends Vue {
  goBack() {
    this.$router.push(`/tarif/exchange-rate/${this.id}`);
  }

  get id(): number {
    return Number(this.$route.params.id);
  }

  disable = true;

  // Loading
  get isLoading() {
    return ExchangeRateController.isLoading;
  }

  // Handle Error
  get isError() {
    return ExchangeRateController.isError;
  }
  get isErrorEdit() {
    return ExchangeRateController.isErrorEdit;
  }
  handleErrorClose() {
    ExchangeRateController.setErrorEdit(false);
  }
  get errorCause() {
    return ExchangeRateController.errorCause;
  }

  // get data
  created() {
    this.getDetail();
  }

  getDetail() {
    ExchangeRateController.getDetailExchange({ id: this.id });
  }

  createdAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";

  exchangeRateValue: any = 0;

  // Detail Data
  get detailData() {
    const detail = ExchangeRateController.exchangeRateDetail;
    this.createdAt = formatDate(detail.createdAt);
    this.createdBy = detail.createdBy;
    this.updatedAt =
      detail.updatedAt === "0001-01-01T00:00:00Z" || detail.updatedAt === null
        ? formatDate(detail.createdAt)
        : formatDate(detail.updatedAt);
    this.updatedBy = detail.updatedBy;
    this.exchangeRateValue = formatPriceWithoutCurrency(detail.exchangeRate);
    return detail;
  }

  // Only number
  formatted() {
    if (/^0/.test(this.exchangeRateValue)) {
      this.exchangeRateValue = this.exchangeRateValue.replace(/^0/, "");
    } else {
      this.exchangeRateValue = this.exchangeRateValue.replace(/[\D]+/g, "");
      this.exchangeRateValue = formatInputMoney(this.exchangeRateValue);
    }
  }

  // Modal Success
  get openSuccess() {
    return ExchangeRateController.openModalSuccess;
  }
  onCloseSuccess() {
    ExchangeRateController.setOpenModalSuccess(false);
  }

  // Save data
  onOpenSave() {
    return ExchangeRateController.setOpenModal(true);
  }
  get openSave() {
    return ExchangeRateController.openModal;
  }
  onCloseSave() {
    ExchangeRateController.setOpenModal(false);
  }
  _onEdit() {
    this.onCloseSave();
    ExchangeRateController._onEditExchange({
      id: Number(this.id),
      exchangeRate: Number(this.exchangeRateValue.split(".").join("")),
      endDate: formatDateNumber(this.periodeEnd)
    });
  }

  // Date
  invalidStartDate = false;
  invalidEndDate = false;
  get periodeStart(): any {
    return ExchangeRateController.convertPeriodeStart;
  }
  get periodeEnd(): any {
    return ExchangeRateController.convertPeriodeEnd;
  }
  get previousEndDate(): any {
    return ExchangeRateController.previousEndDate;
  }
  inputDateStart(param: Date) {
    ExchangeRateController.setPeriodeStart(param);
    const secondDate = new Date(this.periodeEnd);
    if (
      param.getTime() > secondDate.getTime() ||
      param.getTime() === secondDate.getTime()
    ) {
      this.invalidStartDate = true;
    } else {
      this.invalidStartDate = false;
    }
  }

  inputDateEnd(param: Date) {
    ExchangeRateController.setPeriodeEnd(param);
    const secondDate = new Date(this.periodeStart);
    if (
      param.getTime() < secondDate.getTime() ||
      param.getTime() === secondDate.getTime()
    ) {
      this.invalidEndDate = true;
    } else {
      this.invalidEndDate = false;
    }
  }

  // Close when changed
  isEditTerminated = false;
  newPath = "";
  answer = false;

  onOpenClosePage(to: any) {
    this.isEditTerminated = true;
    this.newPath = to.path;
  }

  handlerClose() {
    this.isEditTerminated = false;
    this.answer = true;
    this.$router.push(this.newPath);
  }

  handlerCancelClose() {
    this.answer = false;
    this.isEditTerminated = false;
  }

  // Ellipsis String
  ellipsisString(text: string) {
    return ellipsisString(text, 50);
  }

  // Object Format
  addObject = "";
  filterInputAddObject(e: any) {
    if (/^0/.test(e.target.value)) {
      e.target.value = e.target.value.replace(/^0/, "");
    } else {
      e.target.value = e.target.value.replace(/[\D]+/g, "");
      e.target.value = formatInputMoney(e.target.value);
    }
    this.addObject = e.target.value;
  }

  // Format Date
  formatDate(date: string) {
    return `${dateToDateString(new Date(date))} ${dateToTime(
      new Date(date)
    )} WIB`;
  }
}
