/* eslint-disable @typescript-eslint/camelcase */
import {
  AddExchangeRateRequestInterface,
  EditExchangeRateRequestInterface
} from "../contracts/ExchangeRateRequest";

export class AddExchangeRateApiRequest
  implements AddExchangeRateRequestInterface {
  private from: string;
  private to: string;
  private exchange_rate: number;
  private start_date: string;
  private end_date: string;

  constructor(
    from: string,
    to: string,
    exchange_rate: number,
    start_date: string,
    end_date: string
  ) {
    this.from = from;
    this.to = to;
    this.exchange_rate = exchange_rate;
    this.start_date = start_date;
    this.end_date = end_date;
  }

  public toJSON() {
    const from = this.from;
    const to = this.to;
    const exchange_rate = this.exchange_rate;
    const start_date = this.start_date;
    const end_date = this.end_date;

    return JSON.stringify({
      from,
      to,
      exchange_rate,
      start_date,
      end_date
    });
  }
}

export class EditExchangeRateApiRequest
  implements EditExchangeRateRequestInterface {
  private id: number;
  private exchange_rate: number;
  private end_date: string;

  constructor(id: number, exchange_rate: number, end_date: string) {
    this.id = id;
    this.exchange_rate = exchange_rate;
    this.end_date = end_date;
  }

  public toJSON() {
    const exchange_rate_id = this.id;
    const exchange_rate = this.exchange_rate;
    const end_date = this.end_date;

    return JSON.stringify({
      exchange_rate_id,
      exchange_rate,
      end_date
    });
  }
}
