/* eslint-disable @typescript-eslint/camelcase */
import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { ExchangeRatePresenter } from "../presenters/ExchangeRatePresenter";
import {
  ExchangeRateEntities,
  ExchangeRateData
} from "@/domain/entities/ExchangeRate";
import { Pagination } from "@/domain/entities/Pagination";
import {
  AddExchangeRateApiRequest,
  EditExchangeRateApiRequest
} from "@/data/payload/api/ExchangeRateApiRequest";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface ExchangeRateState {
  exchangeRateData: ExchangeRateEntities;
  exchangeRateDetail: ExchangeRateData;
  isLoading: boolean;
  isError: boolean;
  isErrorEdit: boolean;
  openModal: boolean;
  openModalSuccess: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "ExchangeRate" })
class ExchangeRateStore extends VuexModule implements ExchangeRateState {
  public exchangeRateData = new ExchangeRateEntities(new Pagination(1, 10), []);
  public exchangeRateDetail = new ExchangeRateData(
    0,
    "",
    "",
    0,
    "",
    "",
    "",
    "",
    "",
    "",
    ""
  );
  public isLoading = false;
  public isError = false;
  public isErrorValidation = false;
  public isErrorEdit = false;
  public openModal = false;
  public page = 1;
  public limit = 10;
  public from = "";
  public to = "";
  public status = "";
  public errorCause = "";
  public firstRequest = true;
  public openModalSuccess = false;
  public errorMessage = "";
  public previousEndDate = new Date();

  @Mutation
  public setExchangeRateData(data: ExchangeRateEntities) {
    this.exchangeRateData = data;
  }

  @Mutation
  public setExchangeRateDetail(data: ExchangeRateData) {
    this.exchangeRateDetail = data;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.firstRequest = value;
  }

  @Mutation
  public setLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorValidation(value: boolean) {
    this.isErrorValidation = value;
  }

  @Mutation
  public setErrorMessage(value: string) {
    this.errorMessage = value;
  }

  @Mutation
  public setErrorEdit(value: boolean) {
    this.isErrorEdit = value;
  }

  @Mutation
  public setOpenModal(value: boolean) {
    this.openModal = value;
  }

  @Mutation
  public setOpenModalSuccess(value: boolean) {
    this.openModalSuccess = value;
  }

  @Mutation
  public setStatus(value: string) {
    this.status = value;
  }

  @Mutation
  public setFrom(value: string) {
    this.from = value;
  }

  @Mutation
  public setTo(value: string) {
    this.to = value;
  }

  @Mutation
  public async nextPage() {
    if (this.page >= 1) {
      this.page = Number(this.page) + 1;
    }
  }

  @Mutation
  public async prevPage() {
    this.page = Number(this.page) - 1;
  }

  @Mutation
  public async setPage(page: number) {
    this.page = page;
  }

  @Mutation
  public async setFirstPage() {
    this.page = 1;
  }

  @Mutation
  private setErrorCause(value: string) {
    this.errorCause = value;
  }

  // Date Picker
  public convertPeriodeStart = new Date();
  public convertPeriodeEnd = new Date();

  @Mutation
  public setPeriodeStart(date: any) {
    this.convertPeriodeStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPreviousEndDate(date: any) {
    this.previousEndDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }
  @Mutation
  public setPeriodeEnd(date: any) {
    this.convertPeriodeEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );
  }

  @Action
  public _onGetList(params: {
    from: string;
    to: string;
    status: string;
    page: number;
    limit: number;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(ExchangeRatePresenter);
    presenter
      .getListExchangeRate(
        params.from,
        params.to,
        params.status,
        params.page,
        params.limit
      )
      .then((res: ExchangeRateEntities) => {
        this.setExchangeRateData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public getDetailExchange(params: { id: number }) {
    this.setLoading(true);
    const presenter = container.resolve(ExchangeRatePresenter);
    presenter
      .getDetailExchangeRate(params.id)
      .then((res: ExchangeRateData) => {
        this.setExchangeRateDetail(res);
        this.setPreviousEndDate(new Date(res.endDate));
        this.setPeriodeStart(new Date(res.startDate));
        this.setPeriodeEnd(new Date(res.endDate));
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onAddExchangeRate(params: {
    from: string;
    to: string;
    exchangeRate: number;
    startDate: string;
    endDate: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ExchangeRatePresenter);
    presenter
      .addExchangeRate(
        new AddExchangeRateApiRequest(
          params.from,
          params.to,
          params.exchangeRate,
          params.startDate,
          params.endDate
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Tambah Exchange Rate Gagal", () => {
            MainAppController.closeErrorMessage();
            this._onAddExchangeRate(params);
          })
        );
      })
      .finally(() => {
        this.setLoading(false);
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public _onEditExchange(params: {
    id: number;
    exchangeRate: number;
    endDate: string;
  }) {
    this.setLoading(true);
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(ExchangeRatePresenter);
    presenter
      .editExchangeRate(
        new EditExchangeRateApiRequest(
          params.id,
          params.exchangeRate,
          params.endDate
        )
      )
      .then(() => {
        this.setOpenModalSuccess(true);
        this.setErrorEdit(false);
        this.setErrorCause("");
      })
      .catch(error => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Ubah Exchange Rate Gagal", () => {
            MainAppController.closeErrorMessage();
            this._onEditExchange(params);
          })
        );
      })
      .finally(() => {
        this.setLoading(false);
        this.setOpenModal(false);
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchExchangeRate() {
    this.setFirstRequest(false);
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: this.exchangeRateData.pagination.page,
      limit: this.exchangeRateData.pagination.limit
    });
  }

  @Action
  public initExchangeRates() {
    this.setFirstPage();
    this.setFirstRequest(true);
    this.setStatus("");
    this.setFrom("");
    this.setTo("");
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: this.exchangeRateData.pagination.page,
      limit: this.exchangeRateData.pagination.limit
    });
  }

  @Action
  public async setNextPage() {
    await this.nextPage();
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: this.exchangeRateData.pagination.page,
      limit: this.exchangeRateData.pagination.limit
    });
  }

  @Action
  public async setPrevPage() {
    if (this.page !== 1) {
      await this.prevPage();
      this._onGetList({
        from: this.from,
        to: this.to,
        status: this.status,
        page: this.exchangeRateData.pagination.page,
        limit: this.exchangeRateData.pagination.limit
      });
    }
  }

  @Action
  public setPageAction(value: number) {
    this.setPage(value);
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: this.exchangeRateData.pagination.page,
      limit: this.exchangeRateData.pagination.limit
    });
  }

  @Action
  public setStatusAction(value: string) {
    this.setStatus(value);
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public setFromAction(value: string) {
    this.setFrom(value);
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: 1,
      limit: 10
    });
  }

  @Action
  public handleError() {
    this.setError(false);
  }

  @Action
  public setToAction(value: string) {
    this.setTo(value);
    this._onGetList({
      from: this.from,
      to: this.to,
      status: this.status,
      page: 1,
      limit: 10
    });
  }
}

export const ExchangeRateController = getModule(ExchangeRateStore);
